import { QueryBoundary } from "@kablamo/kerosene-ui/react-query";
import {
  Alert,
  DataTable,
  DEFAULT_ERROR_MESSAGE,
  DivButton,
  SubTR,
  type SubRowComponentProps,
} from "@kablamooss/geo-ds-core-components";
import type { ColumnSort } from "@tanstack/react-table";
import { useState } from "react";
import {
  getGetProjectsProjectIdRunsQueryKey,
  getProjectsProjectIdRuns,
} from "../../../.rest-hooks/runs";
import type { GetProjectsSortBy } from "../../../.rest-hooks/types";
import { projectsSortingRules } from "../../../config/projectsSortingRules";
import useGetAllPages from "../../../hooks/useGetAllPages";
import { useSorting } from "../../../hooks/useSorting";
import type { BfrmpProject } from "../../../types";
import FlexSpinner from "../../ui/FlexSpinner/FlexSpinner";
import ProjectsSubDataTable from "./ProjectsSubDataTable";
import { columns, type ProjectRow } from "./columns";

const SubRowComponent = ({
  isExpanded,
  row,
}: SubRowComponentProps<ProjectRow>) => {
  const runsQuery = useGetAllPages(
    getGetProjectsProjectIdRunsQueryKey,
    getProjectsProjectIdRuns,
    [row.id],
    {},
    {
      enabled: isExpanded,
    },
  );

  return (
    <SubTR data-testid="project-sub-row" isExpanded={isExpanded}>
      <QueryBoundary
        query={runsQuery}
        errorFallback={
          <Alert variant="error" title="Unable to load scenarios">
            {DEFAULT_ERROR_MESSAGE}
          </Alert>
        }
        loadingFallback={<FlexSpinner padding="1rem" />}
      >
        {({ data: runs }) => <ProjectsSubDataTable runs={runs} />}
      </QueryBoundary>
    </SubTR>
  );
};

interface ProjectsDataTableProps {
  onSortChange: (sortBy?: GetProjectsSortBy) => void;
  page: number;
  perPage: number;
  projects: readonly BfrmpProject[];
  sortBy: GetProjectsSortBy;
}

const ProjectsDataTable = ({
  onSortChange,
  page,
  perPage,
  projects,
  sortBy,
}: ProjectsDataTableProps) => {
  const [expandedIds, setExpandedIds] = useState<string[]>([]);

  const tableData: ProjectRow[] = projects.map((project) => ({
    activity: project.activity,
    bfmcs: project.bfmcs,
    createdAt: project.createdAt,
    currentStage: project.currentStage,
    id: project.id,
    lastActivityDateTime: project.lastActivityDateTime,
    owner: project.owner,
    name: project.name,
    status: project.status,
  }));

  const sorting = useSorting({
    sortBy,
    sortingRules: projectsSortingRules,
  });

  const onSortingChange = (sort?: ColumnSort) => {
    if (!sort) {
      onSortChange();
      return;
    }

    onSortChange(`${sort.desc ? "-" : ""}${sort.id}` as GetProjectsSortBy);
  };

  return (
    <DataTable
      clickableRows
      columns={columns}
      data={tableData}
      data-testid="projects-table"
      enableSortingRemoval={false}
      expandedIds={expandedIds}
      getRowId={(row) => row.id}
      onExpandedIdsChange={(ids) => {
        setExpandedIds(ids);
      }}
      onSortingChange={onSortingChange}
      page={page}
      perPage={perPage}
      renderRow={(row, rowElement) => {
        return (
          <DivButton onClick={() => row.toggleExpanded(!row.getIsExpanded())}>
            {rowElement}
          </DivButton>
        );
      }}
      subRowComponent={SubRowComponent}
      sorting={sorting}
    />
  );
};

export default ProjectsDataTable;
